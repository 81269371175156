import CardGroup from 'react-bootstrap/CardGroup';
import Card from 'react-bootstrap/Card';
import HaleBigSize from '../../Imagini/ImaginiNoi/Servici/4_Hala.jpg'
import Hale2BigSize from '../../Imagini/ImaginiNoi/Servici/4_Hala3.jpg'
import SpalatoriAutoBigSize from '../../Imagini/ImaginiNoi/Servici/7_spalatorie_auto_ultima2.jpg'
import BetonCuQuartRosuBigSize from '../../Imagini/ImaginiNoi/Servici/QuartRosu.jpg';
import BetonColoratBigSize from '../../Imagini/ImaginiNoi/Servici/5_curte.jpg';
import BetonCuQuartSiSigilantBigSize from '../../Imagini/ImaginiNoi/Servici/28.jpg';
import ServiceuriBigSize from '../../Imagini/ImaginiNoi/Servici/1_Benzinarie.jpg';
import BetonCurteBigSize from '../../Imagini/ImaginiNoi/Servici/5_curte2.jpg';

import HaleSmallSize from '../../Imagini/ImaginiNoi/Servici/4_Hala.jpg'
import Hale2SmallSize from '../../Imagini/ImaginiNoi/Servici/4_Hala3.jpg'
import SpalatoriAutoSmallSize from '../../Imagini/ImaginiNoi/Servici/7_spalatorie_auto_ultima2.jpg'
import BetonCuQuartRosuSmallSize from '../../Imagini/ImaginiNoi/Servici/QuartRosu.jpg';
import BetonColoratSmallSize from '../../Imagini/ImaginiNoi/Servici/5_curte.jpg';
import BetonCuQuartSiSigilantSmallSize from '../../Imagini/ImaginiNoi/Servici/28.jpg';
import ServiceuriSmallSize from '../../Imagini/ImaginiNoi/Servici/1_Benzinarie.jpg';
import BetonCurteSmallSize from '../../Imagini/ImaginiNoi/Servici/5_curte2.jpg';

import './Servicii.css'
import React, { useEffect, useState } from 'react';
import SimpleLightbox from 'simplelightbox';
import 'simplelightbox/dist/simple-lightbox.min.css';


const Servicii = () => {
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 425);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 425);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

   

    const lightbox = new SimpleLightbox('.Servicii a');
    // Replace '.gallery a' with the CSS selector of your image gallery container

    // Additional configurations (optional)
   // lightbox.open(); // Open the lightbox on component mount
    lightbox.on('closed.simplelightbox', () => {
      // Handle event when the lightbox is closed
    });

    return () => {
      lightbox.destroy(); // Destroy the lightbox on component unmount
       // Cleanup the event listener on component unmount
       window.removeEventListener('resize', handleResize);
    
    };
  }, []);

  const Hale = isLargeScreen ? HaleBigSize : HaleSmallSize;
  const Hale2 = isLargeScreen ? Hale2BigSize : Hale2SmallSize;
  const SpalatoriAuto = isLargeScreen ? SpalatoriAutoBigSize : SpalatoriAutoSmallSize;
  const BetonCuQuartRosu = isLargeScreen ? BetonCuQuartRosuBigSize : BetonCuQuartRosuSmallSize;
  const BetonColorat = isLargeScreen ? BetonColoratBigSize : BetonColoratSmallSize;
  const BetonCuQuartSiSigilant = isLargeScreen ? BetonCuQuartSiSigilantBigSize : BetonCuQuartSiSigilantSmallSize;
  const Serviceuri = isLargeScreen ? ServiceuriBigSize : ServiceuriSmallSize;
  const BetonCurte = isLargeScreen ? BetonCurteBigSize : BetonCurteSmallSize;
  return (
    <div>
 <section id="servicii">
      <div className="container">
        <h1 className="header" ><span>Servicii</span> Oferite</h1>
        <span>Betonul elicopterizat este un tip de amenajare specific suprafetelor orizontale sau in rampa, care a castigat teren in ultima perioada datorita caracteristicilor sale. 
    Avand la baza materii prime din categoria betonului, imbunatatite cu produse speciale pe baza de compusi din quart, minerale, ciment si pigmenti de culoare, betonul elicopterizat acopera pardoselile cu un strat suficient de dur pentru a suporta traficul intens auto sau pietonal, dar si presiunea obiectelor cu greutate. 🚚🚛</span>
     
     
      </div>
      </section>
    
     <CardGroup className="Servicii">
     <Card>
       <a href={Hale} ><Card.Img variant="top" alt='Pardoseli Industriale Hala' title='Pardoseli Industriale Hala' src={Hale} /></a>
       <Card.Body>
         <Card.Title><strong><h3>Turnări hale/depozite industiale</h3></strong></Card.Title>
         <Card.Text>
         
         </Card.Text>
       </Card.Body>
     </Card>
     <Card>
     <a href={Hale2}> <Card.Img variant="top" alt='Pardoseli Industriale Turnari placi / radiere blocuri depozite' title='Pardoseli Industriale Turnari placi / radiere blocuri depozite' src={Hale2}  /> </a>
       <Card.Body>
         <Card.Title><strong><h3>Turnari placi / radiere blocuri depozite</h3></strong></Card.Title>
         <Card.Text>
        
         </Card.Text>
       </Card.Body>
     </Card>
     <Card>
     <a href={SpalatoriAuto}><Card.Img variant="top" alt='Pardoseli Industriale Spalatorie Turnări șape Spălătorii auto' title='Pardoseli Industriale Spalatorie Turnări șape Spălătorii auto' src={SpalatoriAuto} /></a>
       <Card.Body>
         <Card.Title><strong><h3>Turnări șape Spălătorii auto</h3></strong></Card.Title>
         <Card.Text>
         
         </Card.Text>
       </Card.Body>
     </Card>
     </CardGroup>
     <CardGroup className="Servicii">
     <Card>
     <a href={SpalatoriAuto}><Card.Img variant="top" alt='Pardoseli Industriale Spalatorie Beton antiderapant periat' title='Pardoseli Industriale Spalatorie Beton antiderapant periat' src={SpalatoriAuto} /></a>
       <Card.Body>
         <Card.Title><strong><h3>Beton antiderapant periat</h3></strong></Card.Title>
         <Card.Text>
         
         </Card.Text>
       </Card.Body>
     </Card>
     <Card>
     <a href={BetonColorat}><Card.Img variant="top"  alt='Pardoseli Industriale Beton colorat' title='Pardoseli Industriale  Beton colorat' src={BetonColorat} /></a>
       <Card.Body>
         <Card.Title><strong><h3>Beton colorat</h3></strong></Card.Title>
         <Card.Text>
        
         </Card.Text>
       </Card.Body>
     </Card>
     <Card>
     <a href={BetonCuQuartRosu}><Card.Img variant="top"  alt='Pardoseli Industriale Beton finisat cu quart' title='Pardoseli Industriale  Beton finisat cu quart' src={BetonCuQuartRosu} /></a>
       <Card.Body>
         <Card.Title><strong><h3>Beton finisat cu quart</h3></strong></Card.Title>
         <Card.Text>
        
         </Card.Text>
       </Card.Body>
     </Card>
     </CardGroup>
     <CardGroup className="Servicii">
     <Card>
     <a href={BetonCuQuartSiSigilant}><Card.Img variant="top" alt='Pardoseli Industriale Beton finisat cu quart și sigilant' title='Pardoseli Industriale  Beton finisat cu quart și sigilant'  src={BetonCuQuartSiSigilant} /></a>
       <Card.Body>
         <Card.Title><strong><h3>Beton finisat cu quart și sigilant</h3></strong></Card.Title>
         <Card.Text>
      
         </Card.Text>
       </Card.Body>
     </Card>
     <Card>
     <a href={Serviceuri}><Card.Img variant="top" alt='Pardoseli Industriale Service-uri și stații ITP' title='Pardoseli Industriale Service-uri și stații ITP' src={Serviceuri}/></a>
       <Card.Body>
         <Card.Title><strong><h3>Service-uri și stații ITP</h3></strong></Card.Title>
         <Card.Text>
        
         </Card.Text>
       </Card.Body>
     </Card>
     <Card>
     <a href={BetonCurte}><Card.Img variant="top" alt='Pardoseli Industriale În curți civile și industriale' title='Pardoseli Industriale În curți civile și industriale' src={BetonCurte} /></a>
       <Card.Body>
         <Card.Title><strong><h3>În curți civile și industriale</h3></strong></Card.Title>
         <Card.Text>
         
         </Card.Text>
       </Card.Body>
     </Card>
     </CardGroup>
   </div>

  );
};

export default Servicii;
