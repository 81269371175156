import React, { createContext, useContext, useState } from 'react';

// Create a context for managing #facebook style
const FacebookContext = createContext();

// Provider component that holds the state and provides it to child components
export function FacebookProvider({ children }) {
  const [facebookTop, setFacebookTop] = useState('0px'); // Initial top value
  return (
    <FacebookContext.Provider value={{ facebookTop, setFacebookTop }}>
      {children}
    </FacebookContext.Provider>
  );
}

// Custom hook to access #facebook style and set its value
export function useFacebookStyle() {
  return useContext(FacebookContext);
}
