import React, { useState } from 'react';
import './CookieBanner.css';


const CookieSettingsModal = ({ onClose, onSaveSettings }) => {
  const [analyticsCookiesEnabled, setAnalyticsCookiesEnabled] = useState(true);
  const [advertisingCookiesEnabled, setAdvertisingCookiesEnabled] = useState(true);

  const handleSavePreferences = () => {
    // Set "Only Essentials" as the default preference
    const userPreference = ['essentials'];



    // Call the onSaveSettings callback to save preferences and close the banner
    onSaveSettings();

    // Close the modal
    onClose();
  };

  return (
    <div className="cookie-settings-modal">
      <div className="modal-content">
        <h2>Cookie Settings</h2>
        <label>
          <input
            type="checkbox"
            checked={true} // Always checked (disabled)
            disabled={true} // Disable user interaction
          />
          Doar esentiale (Intotdeauna Pornite)
        </label>
        <label>
          <input
            type="checkbox"
            checked={analyticsCookiesEnabled}
            onChange={() => setAnalyticsCookiesEnabled(!analyticsCookiesEnabled)}
          />
          Activați cookie-urile de analiză
        </label>
        <label>
          <input
            type="checkbox"
            checked={advertisingCookiesEnabled}
            onChange={() => setAdvertisingCookiesEnabled(!advertisingCookiesEnabled)}
          />
          Activați cookie-urile de publicitate
        </label>
        <button onClick={handleSavePreferences}>Salvati Setarile</button>
      </div>
    </div>
  );
};

export default CookieSettingsModal;
