import Button from '@mui/material/Button';
import "./footer.css"
import { Link, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';


function Footer() {
  const location = useLocation();
  const [isGDPRPagina, setIsGDPRPage] = useState(false);
  const [iscookiesPagina, setIscookiesPage] = useState(false);

  useEffect(() => {
    setIsGDPRPage(location.pathname === '/GDPR');
  }, [location.pathname]);

  
  useEffect(() => {
    setIscookiesPage(location.pathname === '/cookies');
  }, [location.pathname]);

  
   
  return (
    <div>
      <footer id="footer" style={{ marginTop: "auto"}}>
        <span><strong>Adresa</strong> : Calea 13 Septembrie, 65-69, Bl:65-67, Sc:2, Et:1, Ap:40, Bucuresti</span>
        <br></br>
        <span><strong>Program</strong> : Luni - Vineri 8:00 - 18:00</span>
        <br></br>
        <span><strong>Telefon</strong> : 0733635031</span>
        <br></br>
        <span><strong>E-mail</strong> : <a style={{textDecorationLine:"none", color:"white"}} href="mailto:contact@betonactiv.ro">contact@betonactiv.ro</a></span>

        <br></br>
        <Button
        id="basic-button"
        >
         {!isGDPRPagina ? (<Link to='/GDPR'>GDPR</Link>) : <span>GDPR</span>}
        </Button>

        <Button
        id="basic-button"
        >
         {!iscookiesPagina ? (<Link to='/cookies'>Politica Cookie</Link>) : <span>Politica Cookies</span>}
        </Button>
        <br/>
        <span style={{ marginTop: "-16px" }}>Copyright &copy; 2023</span>
        

      </footer>
    </div>
  );
}

export default Footer;
