import BetonCuQuarts from './BetonCuQuarts';
import BetonArmat from './BetonArmat';
import BetonAntiderapant from './BetonAntiderapant';
import BetonColorat from './BetonColorat';
import FolosireaBetonului from '../01. Acasa/FolosireaBetonului';
import VideoInformatiiPage from './VideoInformatiiPage'
import './ServiciiGalerie.css';


export default function ServiciiGalerie() {


    return (
        <>
        
		 <section id="showcase" >
			<VideoInformatiiPage />
		  </section>
          <div id="Pardoseli Industriale">
          <FolosireaBetonului />

          </div>

        <div id="Beton cu cuart">
        <BetonCuQuarts />        

        </div>
        <div id="Beton armat">
        <BetonArmat />

        </div>
        <div id="Beton antiderapant">
        <BetonAntiderapant />

        </div>
        <div id="Beton colorat">
        <BetonColorat />
        
        </div>
        </>
    )
}