import { useState, useEffect } from 'react';
import './Header.css'
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link, useLocation } from 'react-router-dom';

export default function Portofolio() {
  const [anchorEl, setAnchorEl] = useState("");
  const open = Boolean(anchorEl);
  const location = useLocation();
  const [isPortofolioPagina, setIsHomePage] = useState(false);
  const [clickPortofolio, setPortofolio] = useState(false);
  const [eventButton, setEventButton] = useState(null);

  useEffect(() => {
    setIsHomePage(location.pathname === '/portofoliu');
    if(clickPortofolio){
      setTimeout(() => {
        setAnchorEl(eventButton);
        setPortofolio(false);
      }, 300);
    }
  }, [location.pathname]);


  const handleClick = (event) => {
    if(isPortofolioPagina){
      setAnchorEl(event.currentTarget);
    }
    setEventButton(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const handleClickToPortofoliu = () => {
   setPortofolio(true);

  }

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className='fromLeft'

      >
      
      {!isPortofolioPagina ? (<Link to='/portofoliu' onClick={handleClickToPortofoliu}>Portofoliu</Link> ) : <span style={{fontSize:"16px"}}>Portofoliu</span>}
        
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClose}><a href="#DespreNoi">Galerie</a></MenuItem>
        <MenuItem onClick={handleClose}>Articole</MenuItem>
        <MenuItem onClick={handleClose}>Recenzi</MenuItem>
        <MenuItem onClick={handleClose}>Comentari</MenuItem>

      </Menu>
    </>
  );
}