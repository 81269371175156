import { useState, useEffect } from 'react';
import './Header.css'
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link, useLocation } from 'react-router-dom';

export default function InformatiiPage() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [clickServicii, setServicii] = useState(false);
  const location = useLocation();
  const [isServiciiPagina, setIsServiciiPage] = useState(false);
  const [eventButton, setEventButton] = useState(null);

  useEffect(() => {
    setIsServiciiPage(location.pathname === '/informatii');
    if(clickServicii){
      setTimeout(() => {
        setAnchorEl(eventButton);
        setServicii(false);
      }, 300);
    }
  }, [location.pathname]);


  const handleClick = (event) => {
    if(isServiciiPagina){
      setAnchorEl(event.currentTarget);
    }  
    setEventButton(event.currentTarget);
    
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickToServicii = () => {
    setServicii(true);

  }

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className='fromLeft'

      >
      
      {!isServiciiPagina ? (<Link to='/informatii' onClick={handleClickToServicii}>Informatii</Link>) : <span style={{fontSize:"16px"}}>Informatii</span>}

      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        disableScrollLock={true}
      >
        <MenuItem onClick={handleClose}><a href="#Pardoseli Industriale">Pardoseli Industriale</a></MenuItem>
        <MenuItem onClick={handleClose}><a href="#Beton cu cuart">Beton cu cuart</a></MenuItem>
        <MenuItem onClick={handleClose}><a href="#Beton armat">Beton armat</a></MenuItem>
        <MenuItem onClick={handleClose}><a href="#Beton antiderapant">Beton antiderapant</a></MenuItem>
        <MenuItem onClick={handleClose}><a href="#Beton colorat">Beton colorat</a></MenuItem>

      </Menu>
    </>
  );
}