import ImageGalleryBeton from './ImageGalleryBeton';
import { useState, useEffect } from 'react';
import fetchImagesCount from '../../Fetch/FetchImages';
const PREFIX_URL = "https://infinitystudiogames.com/beton/ImaginiNoi/BetonColorat/Rosu/";
const PREFIX_URLThumbnails = "https://infinitystudiogames.com/beton/ImaginiNoi/thumbnails/BetonColorat/Rosu/";
const PREFIX_URLMOBILE = "https://infinitystudiogames.com/beton/ImaginiNoi/mobile/BetonColorat/Rosu/";
const videoCarousel = "https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FAurel071986%2Fvideos%2F323365993351457%2F&show_text=false&t=1";

const PREFIX_URLVerde = "https://infinitystudiogames.com/beton/ImaginiNoi/BetonColorat/Verde/";
const PREFIX_URLThumbnailsVerde = "https://infinitystudiogames.com/beton/ImaginiNoi/thumbnails/BetonColorat/Verde/";
const PREFIX_URLMOBILEVerde = "https://infinitystudiogames.com/beton/ImaginiNoi/mobile/BetonColorat/Verde/";

const PREFIX_URLTabac = "https://infinitystudiogames.com/beton/ImaginiNoi/BetonColorat/Tabac/";
const PREFIX_URLThumbnailsTabac = "https://infinitystudiogames.com/beton/ImaginiNoi/thumbnails/BetonColorat/Tabac/";
const PREFIX_URLMOBILETabac = "https://infinitystudiogames.com/beton/ImaginiNoi/mobile/BetonColorat/Tabac/";



export default function BetonColorat(){
    const [numberOfImages, setNumberImages] = useState(0);
    const [numberOfImagesVerde, setNumberImagesVerde] = useState(0);
    const [numberOfImagesTabac, setNumberImagesTabac] = useState(0);

    useEffect(() => { 
        fetchImagesCount({ folderImages: 'BetonColorat/Rosu' })
          .then(count => {
           // console.log('Number of images Rosu:', count);
            setNumberImages(parseInt(count));
          })
          .catch(error => {
            // Handle the error
          });

          fetchImagesCount({ folderImages: 'BetonColorat/Verde' })
          .then(count => {
           // console.log('Number of images Verde:', count);
            setNumberImagesVerde(parseInt(count));
          })
          .catch(error => {
            // Handle the error
          });

          fetchImagesCount({ folderImages: 'BetonColorat/Tabac' })
          .then(count => {
            //console.log('Number of images Tabac:', count);
            setNumberImagesTabac(parseInt(count));
          })
          .catch(error => {
            // Handle the error
          });
        }, []);

    return(
        <>
        <br/>
        <br/>
        <br/>

        <div className='container'>
            <h1>Ce este beton-ul colorat?</h1>
        <span>
Betonul colorat este un tip special de beton în care se adaugă pigmenți sau coloranți pentru a conferi suprafeței o culoare sau o nuanță specifică. Acesta reprezintă o alternativă estetică și creativă la betonul obișnuit, care este în mod tradițional gri sau neutru din punct de vedere cromatic. Betonul colorat se folosește într-o varietate de aplicații, de la pavaje și trotuare la podele interioare și exterioare, pereți, blaturi de bucătărie, și chiar în proiecte artistice și decorative.

Pigmenții sau coloranții folosiți pentru a colora betonul pot fi de natură minerală sau sintetică și sunt disponibili într-o gamă largă de culori. Aceștia pot fi amestecați cu betonul proaspăt sau aplicați pe suprafața betonului prin diverse tehnici pentru a obține efecte decorative specifice.</span>
        </div>
        {numberOfImages > 0 && (
        <ImageGalleryBeton  verification={0} prefixURL={PREFIX_URL}  PREFIX_URLMOBILE={PREFIX_URLMOBILE}  thumbnailURL={PREFIX_URLThumbnails} video={videoCarousel} numberOfImages={numberOfImages} />
      )}
        {numberOfImagesVerde > 0 && (
        <ImageGalleryBeton  verification={0} prefixURL={PREFIX_URLVerde}  PREFIX_URLMOBILE={PREFIX_URLMOBILEVerde}  thumbnailURL={PREFIX_URLThumbnailsVerde} video={videoCarousel} numberOfImages={numberOfImagesVerde} />
      )}

        {numberOfImagesTabac > 0 && (
        <ImageGalleryBeton  verification={0} prefixURL={PREFIX_URLTabac}  PREFIX_URLMOBILE={PREFIX_URLMOBILETabac}  thumbnailURL={PREFIX_URLThumbnailsTabac} video={videoCarousel} numberOfImages={numberOfImagesTabac} />
      )}
        </>
    );
    
}

 