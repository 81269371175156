import React, { useEffect } from 'react';

const PoliticaDeConfidentialitate = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);
  


    return (
      <>
      <div className="container">
      
      <br></br>
     <h3 style={{textAlign:"center"}}>Politica De Confidentialitate</h3>
     <span>
      Societatea prelucreaza o serie de date cu caracter personal ale Utilizatorilor (fie ca sunt sau nu inregistrati) atunci cand utilizeaza Site-ul https://betontehnic.ro/ si/sau orice Serviciu oferit de catre compania noastra. Aceasta politica de confidentialitate descrie ce tipuri de date cu caracter personal sunt prelucrate, cum sunt acestea utilizate, care sunt optiunile dvs. in legatura cu aceste prelucrari, precum si modul in care vom respecta drepturile pe care le aveti in calitate de persoana vizata conform legislatiei privind protectia datelor cu caracter personal, inclusiv Regulamentul (UE) 2016/679 („GDPR”). 
      <br></br>
      INAINTE DE A UTILIZA SITE-UL SAU ORICARE DINTRE SERVICIILE NOASTRE, VA RECOMANDAM SA CITITI CU ATENTIE ACEASTA POLITICA PENTRU A INTELEGE CUM VA SUNT PRELUCRATE DATELE CU CARACTER PERSONAL.
      </span>
      <br></br>
      <span>
1. CINE ESTE RESPONSABIL DE PRELUCRAREA DATELOR DVS.?  <br></br>
S. C. BETON TEHNIC ACTIV S. R. L., cu sediul în Romania, este operatorul datelor cu caracter personal, conform legislatiei privind protectia datelor cu caracter personal inclusiv GDPR, in ceea ce priveste datele cu caracter personal ale Utilizatorilor colectate si prelucrate prin intermediul Site-ului https://betontehnic.ro/ si/sau in contextul Serviciilor oferite prin intermediul sau in legatura cu Site-ul. Pentru activitatea de prelucrare a datelor cu caracter personal Societatea poate fi contactata folosind urmatoarele date de contact: contact@betonactiv.ro <br></br>
2. CE DATE PRELUCRAM? <br></br>
Societatea prelucrează datele personale pe care ni le furnizaţi atunci când utilizaţi Site-ul, va inregistrati pe pagina noastra, contractati un serviciu, vă înscrieţi în campaniile noastre (si/sau campaniile organizate impreuna cu partenerii nostri), doriţi să primiţi comunicări comerciale sau să participaţi la sondajele şi chestionarele noastre, sau ne contactati prin orice mijloace de comunicare In cazul in care contactat compania noastra S. C. BETON TEHNIC ACTIV S. R. L. prin intermediul formularului de contact prezent pe site-ul https://betontehnic.ro/, datele communicate de dvs.( nume, prenume, adresa, telefon,email si orice alte date personale comunicate) vor fii stocate si prelucrate doar dupa bifarea acceptului expres prezent in partea de jos a formularului de contact.De asemenea, utilizam module cookie si tehnologii similare pentru a va recunoaste pe dvs. si dispozitivele dvs. De asemenea, permitem altor persoane sa utilizeze module cookie. Modul in care utilizam aceste tehnologii sunt descrise in Politica privind Cookies.. <br></br>
3. FURNIZAREA UNOR OFERTE PERSONALIZATE <br></br>
În anumite situaţii, putem utiliza informaţiile colectate de la dvs. în combinaţie cu datele pe care le obţinem de la echipele noastre de vânzări şi/sau de marketing cu privire la interacţiunea dvs. cu Societatea, pe care să le folosim în contextul comunicărilor noastre de marketing. Dorim să eficientizăm activitatea noastră de marketing, oferind clienţilor noştri produse / servicii relevante şi personalizate.[De asemenea, dacă sunteţi de acord, putem efectua analize avansate cu privire la interesele şi preferinţele dvs. rezultând din informaţiile pe care le deţinem despre dvs. din interacţiunea cu noi, precum şi prin combinarea datelor obţinute de la terţi (e.g. prin intermediul tehnologiilor de analiză a navigării web) pentru a vă transmite comunicări comerciale i adaptate nevoilor şi preferinţelor dvs.] Putem realiza diverse raportări, analize şi studii statistice cu privire la campaniile de marketing organizate şi succesul acestora, activitatea de vânzări / prelucrare. <br></br>
4. EXECUTAREA CONTRACTULUI INCHEIAT CU NOI <br></br>
Atunci când solicitati furnizarea unor servicii putem prelucra anumite date personale necesare pentru aceste scopuri (e.g. nume şi prenume, adresa de livrare / furnizare, date de tranzacţionare, etc.) <br></br>
5. GESTIUNEA RAPORTULUI DINTRE UTILIZATORI SI ASISTENTA <br></br>
Avem interesul sa oferim servicii adecvate prin intermediul Site-ului tuturor persoanelor care il acceseaza. Prin urmare, prelucram o serie de date cu caracter personal pentru scopul gestiunii relatiilor cu Utilizatorii Site-ului, spre exemplu atunci cand ne contacteaza cu diverse intrebari sau solicitari privind functionalitatile Site-ului, intrebari cu privire la serviciile Societatii oferite prin Site etc. Temeiul juridic: art. 6(1)(f) GDPR – interes legitim constand in interesul nostru de a asigura servicii adecvate. <br></br>
6. ANALIZE SI STATISTICI PRIVIND FUNCTIONAREA SITE-ULUI, COOKIES SI TEHNOLOGII SIMILARE <br></br>
Putem folosi datele cu caracter personal pe care le furnizeaza Utilizatorii sau pe care le colectam in contextul utilizarii Serviciilor pentru scopul efecturarii unor analize si statistici privind Serviciile noastre, inclusiv a modului in care functioneaza Site-ul sau sunt oferite Serviciile. Analizele si statisticile pe care le facem ne ajuta sa intelegem mai bine cum am putea sa imbunatatim Serviciile noastre sau functionalitatile Site-ului.In efectuarea analizelor si statisticilor utilizam de asemenea cookies si alte tehnologii similare conform Politicii privind Cookies. De asemenea, cookie-urile si tehnologiile similare sunt utilizate pentru a va oferi publicitate bazata pe interes. <br></br>
7. CONECTARE PRIN RETELE DE SOCIALIZARE <br></br>
Am implementat diverse mecanisme de interconectare cu paginile de socializare, cum ar fi Facebook, Twitter, Youtube si Instagram si astfel sa aveti posiblitatea sa accesati continutul postat de noi pe Site si sau conturile noastre asociate pe acele retele sociale mai facil.Daca accesati contintul postat pe acele retele de socializare sau comentati pe acele retele de socializare, sau accesati Site-ul prin intermediul contului de Facebook, o serie de date publice din profilul dvs. de pe acele retele de socializare ne vor fi transmise si noua de catre operatorii acelor retele de socializare. <br></br>
8.ÎNDEPLINIREA UNOR OBLIGATII LEGALE <br></br>
Uneori prelucrarea datelor este necesară pentru a ne îndeplini obligaţiile legale ce ne revin, cum ar fi: • raportarea către autorităţile fiscale relevante şi ţinerea unor evidenţe contabile; • arhivarea datelor conform legislaţiei aplicabile. <br></br>
9.APARAREA DREPTURILOR SI INTERESELOR IN JUSTITIE <br></br>
Pentru constatarea, exercitarea sau apărarea unui drept în justiţie în cadrul unei proceduri în faţa unei instanţe, a unei proceduri administrative sau a altor proceduri oficiale în care este implicată Societatea. <br></br>
10. CUI DEZVALUIM DATELE <br></br>
Putem dezvalui datele dvs cu caracter personal catre (i) entitatile si/sau persoanele imputernicite de noi (din EEA sau din state terte) implicate in furnizarea Serviciilor, inclusiv in furnizarea comunicarilor comerciale (cum ar fi furnizorilor de centre de date, furnizori de servicii de plata pentru diverse facilitati de plata, furnizorii de platforme de e-mailing cum ar fi Google Mail), sau daca avem obligatia de a divulga datele personale in scopul conformarii cu orice obligatie legala sau decizie a unei autoritati judiciare, autoritati publice sau organ guvernamental; sau (iii) daca ni se cere sau ni se permite in alt mod sa facem acest lucru conform legislatie aplicabile.De asemenea, datele dvs cu caracter personale pot fi dezvaluite tertilor furnizori de cookies si tehnologii similare conform celor descrise in Politici privind Cookies. <br></br>
11. CAT PASTRAM DATELE <br></br>
Pastram datele dvs cu caracter personal cat este necesar pentru indeplinirea scopurilor pentru care au fost colectate, cu respectarea procedurilor interne privind retentia datelor, inclusiv a regulilor de arhivare aplicabile. Spre exemplu, daca sunteti un client de-al nostru, datele dvs. caracter personal vor fi pastrate pe intreaga durata a raporturilor contractuale, plus o perioada minima de 3 ani de la incetare (cat este termenul de prescriptie pentru actiuni in justitie). Ulterior, anumite date cu caracter personal ar putea fi pastrate pentru o perioada mai lunga, pentru scop de arhivare, conform regulilor de arhivare aplicabile. <br></br>
12. CE DREPTURI AVETI IN CALITATE DE PERSOANA VIZATA? <br></br>
Conform legii, va sunt recunoscute urmatoarele drepturi in calitate de persoana vizata: 
<br></br>
a)Dreptul de acces puteti obtine de la noi confirmarea ca prelucram datele dvs. personale, precum si informatii privind specificul prelucrarii 
<br></br>
b)Dreptul de modificare puteti ne solicitati modificam datele dvs. personale incorecte ori, dupa caz, completarea datelor care sunt incomplete. 
<br></br>
c)Dreptul la stergere puteti solicita stergerea datelor personale atunci cand: (i) acestea nu mai sunt necesare pentru scopurile pentru care le-am colectat si le prelucram; (ii) v-ati retras consimtamantul pentru prelucrarea datelor si noi nu le mai putem prelucra pe alte temeiuri legale; (iii) datele sunt prelucrate contrar legii; respectiv (iv) datele trebuie sterse conform legislatiei relevante. 
<br></br>
d)Retragerea consimtamantului si dreptul de opozitie puteti sa va retrageti oricand consimtamantul cu privire la prelucrarea datelor pe baza de consimtamant. De asemenea va puteti opune oricand prelucrarilor pentru scop de marketing, inclusiv profilarilor efectuate in acest scop, precum si prelucrarilor bazate pe interesul legitim al Societatii, din motive care tin de situatia dvs. Specifica. 
<br></br>
e)Restrictionare in anumite conditii puteti solicita restrictionarea prelucrarii datelor dvs. Personale 
<br></br>
f)Dreptul la portabilitatea datelor in masura in care prelucram datele prin mijloace automate, puteti sa ne solicitati, in conditiile legii, sa furnizam datele dvs. intr-o forma structurata, utilizata frecvent si care poate fi citita in mod automat. Daca ne solicitati acest lucru, putem sa transmitem datele dvs. unei alte entitati, daca este posibil din punct de vedere tehnic. 
<br></br>
g)Dreptul de a depune o plangerela autoritatea de supraveghere Aveti dreptul de a depune o plangere la autoritatea de supraveghere a prelucrarii datelor in cazul in care considerati ca v-au fost incalcate drepturile:Autoritatea Nationala pentru Supravegherea Datelor cu Caracter Personal din Romania B-dul G-ral. Gheorghe Magheru 28-30 Sector 1, cod postal 010336 Bucuresti, Romania anspdcp@dataprotection.ro <br></br> 
PENTRU EXERCITAREA DREPTURILOR MENTIONATE MAI SUS, NE PUTETI CONTACTA UTILIZAND ADRESA DE Email : contact@betonactiv.ro
<br></br>
<br></br>
     </span>
     </div>
     </>
  );
};

export default PoliticaDeConfidentialitate;
