import ImageGalleryBeton from './ImageGalleryBeton';
import { useState, useEffect } from 'react';
import fetchImagesCount from '../../Fetch/FetchImages';
const PREFIX_URL = "https://infinitystudiogames.com/beton/ImaginiNoi/BetonCuCuarts/";
const PREFIX_URLThumbnails = "https://infinitystudiogames.com/beton/ImaginiNoi/thumbnails/BetonCuCuarts/";
const videoCarousel = "https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FAurel071986%2Fvideos%2F323365993351457%2F&show_text=false&t=1";
const PREFIX_URLMOBILE = "https://infinitystudiogames.com/beton/ImaginiNoi/mobile/BetonCuCuarts/";

export default function BetonCuQuarts(){
    const [numberOfImagesQuarts, setNumberImages] = useState(0);
    useEffect(() => { 
        fetchImagesCount({ folderImages: 'BetonCuCuarts' })
          .then(count => {
            //console.log('Number of images:', count);
            setNumberImages(parseInt(count));
          })
          .catch(error => {
            // Handle the error
          });
        }, []);

    return(
        <>
        <br/>
        <br/>
        <br/>

        <div className='container'>
            <h1>Cuartul: Secretul pentru Suprafețe de Beton Lucioase și Durabile</h1>
        <span>Când vine vorba de construcția sau renovarea suprafețelor de beton, cuartul este un ingredient secret care poate face diferența între un proiect mediocru și unul cu rezultate spectaculoase. Acest material granular, compus din particule fine de cuarț sau alte minerale dure, este utilizat pentru a întări și a conferi o strălucire specială betonului, creând un efect de luciu tip "oglindă". </span>
        </div>
        {numberOfImagesQuarts > 0 && (
        <ImageGalleryBeton  verification={0} prefixURL={PREFIX_URL} PREFIX_URLMOBILE={PREFIX_URLMOBILE} thumbnailURL={PREFIX_URLThumbnails} video={videoCarousel} numberOfImages={numberOfImagesQuarts} />
      )}
        </>
    );
    
}

 