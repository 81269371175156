import ImageGalleryBeton from './ImageGalleryBeton';
import { useState, useEffect } from 'react';
import fetchImagesCount from '../../Fetch/FetchImages';
const PREFIX_URL = "https://infinitystudiogames.com/beton/ImaginiNoi/BetonArmat/";
const videoCarousel = "https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FAurel071986%2Fvideos%2F323365993351457%2F&show_text=false&t=1";
const PREFIX_URLThumbnails = "https://infinitystudiogames.com/beton/ImaginiNoi/thumbnails/BetonArmat/";
const PREFIX_URLMOBILE = "https://infinitystudiogames.com/beton/ImaginiNoi/mobile/BetonArmat/";
export default function BetonArmat(){
    const [numberOfImages, setNumberImages] = useState(0);
    useEffect(() => { 
        fetchImagesCount({ folderImages: 'BetonArmat' })
          .then(count => {
            //console.log('Number of images:', count);
            setNumberImages(parseInt(count));
          })
          .catch(error => {
            // Handle the error
          });
        }, []);

    return(
        <>
        <br/>
        <br/>
        <br/>

        <div className='container'>
            <h1>Ce este beton armat?</h1>
        <span>Betonul armat este un material de construcție compus dintr-o combinație de beton și o rețea de armături metalice (de obicei din oțel). Acest tip de beton este cunoscut și sub denumirea de "beton cu armături" sau "beton cu bare de oțel" și este utilizat pe scară largă în industria construcțiilor pentru a conferi structurilor rezistență mecanică și durabilitate. </span>
        </div>
        {numberOfImages > 0 && (
        <ImageGalleryBeton  verification={0} prefixURL={PREFIX_URL}  PREFIX_URLMOBILE={PREFIX_URLMOBILE}  thumbnailURL={PREFIX_URLThumbnails} video={videoCarousel} numberOfImages={numberOfImages} />
      )}
        </>
    );
    
}

 