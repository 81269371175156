import ImageGalleryBeton from './ImageGalleryBeton';
import { useState, useEffect } from 'react';
import fetchImagesCount from '../../Fetch/FetchImages';
const PREFIX_URL = "https://infinitystudiogames.com/beton/ImaginiNoi/BetonAntiderapant/";
const videoCarousel = "https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FAurel071986%2Fvideos%2F323365993351457%2F&show_text=false&t=1";
const PREFIX_URLThumbnails = "https://infinitystudiogames.com/beton/ImaginiNoi/thumbnails/BetonAntiderapant/";
const PREFIX_URLMOBILE = "https://infinitystudiogames.com/beton/ImaginiNoi/mobile/BetonAntiderapant/";
export default function BetonAntiderapant(){
    const [numberOfImages, setNumberImages] = useState(0);
    useEffect(() => { 
        fetchImagesCount({ folderImages: 'BetonAntiderapant' })
          .then(count => {
            //console.log('Number of images:', count);
            setNumberImages(parseInt(count));
          })
          .catch(error => {
            // Handle the error
          });
        }, []);

    return(
        <>
        <br/>
        <br/>
        <br/>

        <div className='container'>
            <h1>Ce este Betonul Antiderapant?</h1>
        <span>
Betonul antiderapant este un tip special de beton conceput pentru a furniza o suprafață cu aderență ridicată, reducând astfel riscul de alunecare sau de alunecare în condiții umede sau cu prezența apei. Acest tip de beton este folosit în principal în aplicații unde siguranța este o prioritate, cum ar fi în zonele cu trafic pietonal sau vehicular, în special acolo unde poate exista expunere la apă sau alte lichide. </span>
        </div>
        {numberOfImages > 0 && (
        <ImageGalleryBeton  verification={0} prefixURL={PREFIX_URL} PREFIX_URLMOBILE={PREFIX_URLMOBILE} thumbnailURL={PREFIX_URLThumbnails} video={videoCarousel} numberOfImages={numberOfImages} />
      )}
        </>
    );
    
}

 