import './DespreNoi.css';
import React, { useState, useEffect } from 'react';
import Logo2 from '../../Imagini/Logo/Artboard 4.png';
import ImageGalleryBeton from  '../02. Informatii/ImageGalleryBeton';
import fetchImagesCount from '../../Fetch/FetchImages';
import FetchVideos from '../../Fetch/FetchVideos';


const PREFIX_URL = "https://infinitystudiogames.com/beton/ImaginiNoi/terasa/";
const PREFIX_URLThumbnails = "https://infinitystudiogames.com/beton/ImaginiNoi/thumbnails/Terasa/";
const PREFIX_URLMOBILE = "https://infinitystudiogames.com/beton/ImaginiNoi/mobile/terasa/";

const PREFIX_URL_EchipaNoastra = "https://infinitystudiogames.com/beton/ImaginiNoi/Toata%20Echipa/"
const PREFIX_URL_EchipaNoastraThumbnails = "https://infinitystudiogames.com/beton/ImaginiNoi/thumbnails/Toata%20Echipa/";
const PREFIX_URLMOBILE_ToataEchipa = "https://infinitystudiogames.com/beton/ImaginiNoi/mobile/Toata%20Echipa/";

const videoCarousel = "https://www.facebook.com/plugins/video.php?height=293&href=https%3A%2F%2Fwww.facebook.com%2FAurel071986%2Fvideos%2F253966407254543%2F&show_text=false&width=600&t=0";

const DespreNoi = () => {
  const [numberOfImagesTerasa, setNumberImages] = useState(0);
  const [numberOfImagesEchipanNoastra, setNumberImagesEchipaNoastra] = useState(6);
  const [videos, setVideos] = useState({});
  //console.log(videos);
  useEffect(() => {
    FetchVideos({ Sheet: 'DespreNoi' })
    .then(videosData => {
      //console.log(videosData);
      setVideos(videosData);
    })
    .catch(error => {
      // Handle the error
    });

    fetchImagesCount({ folderImages: 'terasa' })
      .then(count => {
       // console.log('Number of images:', count);
        setNumberImages(parseInt(count));
      })
      .catch(error => {
        // Handle the error
       // console.log("Terasa")
      });

      fetchImagesCount({ folderImages: 'Toata Echipa' })
      .then(count => {
        //console.log('Number of images:', count);
        setNumberImagesEchipaNoastra(parseInt(count));
      })
      .catch(error => {
        // Handle the error
      });
  }, []);

  return (
    <div>
      {numberOfImagesTerasa > 0 && (
        <ImageGalleryBeton  verification={0} prefixURL={PREFIX_URL} PREFIX_URLMOBILE={PREFIX_URLMOBILE} thumbnailURL={PREFIX_URLThumbnails} video={videoCarousel} numberOfImages={numberOfImagesTerasa} />
      )}
      <section id="DespreNoiBlock">
        <div className="container">
          <h2 className="header" style={{ textAlign: "center" }}><span>Despre</span> Noi</h2>
          <img src={Logo2} />
          <br />
          <span>
            S.C. BETON TEHNIC ACTIV S.R.L este o companie cu capital integral romanesc, o companie dinamica si totodata ancorata in evolutia si cerintele pietii, avand personal calificat cu experienta in domeniul Pardoselilor Industriale Elicopterizate, efectuam <span style={{ fontWeight: "bolder" }}>turnari beton</span> in toata tara.
          </span>
          <br></br>
          <br></br>
          <span>Ne plac provocarile si gasim solutii potrivite pentru orice situatie!
            Compania noastra va pune la dispozitie o echipa de profesionisti bine pregatiti, capabila sa execute cele mai pretentioase lucrari de beton elicopterizat, garantandu-va calitate, seriozitate si promptitudine in executia lucrarilor.
            Pentru mai multe detalii referitoare la serviciile pe care le oferim va rugam sa ne contactati!</span>
        </div>
        <br></br>
      </section>
     {videos.length > 0 && (
        <ImageGalleryBeton 
          verification={1}
          videos={videos}
          imgVideos={videos}
          prefixURL={PREFIX_URL_EchipaNoastra}
          numberOfImages={numberOfImagesEchipanNoastra}
          thumbnailURL={PREFIX_URL_EchipaNoastraThumbnails}
          PREFIX_URLMOBILE={PREFIX_URLMOBILE_ToataEchipa}
        />
      )}
    </div>
  );
};

export default DespreNoi;
