function fetchImagesCount({ folderImages }) {
    const folderName = folderImages;
  
    return fetch(`https://infinitystudiogames.com/beton/ImaginiNoi/image_counter.php?folder=${folderName}`)
      .then(response => response.text())
      .then(data => {
       //console.log(data); // The response from the PHP script (the file count)
        const numberOfImages = parseInt(data);
        return numberOfImages;
      })
      .catch(error => {
        console.log('Error:', error);
        throw error; // Rethrow the error to be handled by the caller
      });
  }
  
  export default fetchImagesCount;