import Card from 'react-bootstrap/Card';
import './TurnariProiecte.css'
import CardGroup from 'react-bootstrap/CardGroup';
import ProjectImg from '../../Imagini/ImaginiNoi/2.1.jpg'
import VechimeImg from '../../Imagini/ImaginiNoi/3.jpg'
import UtilajeImg from '../../Imagini/ImaginiNoi/4.jpg'
import {useEffect, useRef, useState, useMemo} from 'react';
import CountUp from 'react-countup';


function useIsVisible(ref) {
    const [isIntersecting, setIntersecting] = useState(false);
 
  
    useEffect(() => {
      const observer = new IntersectionObserver(([entry]) =>
        setIntersecting(entry.isIntersecting)
      );
  
      observer.observe(ref.current);
      return () => {
        observer.disconnect();
      };
    }, [ref]);
  
    return isIntersecting;
  }


function TurnariProiecte() {


 const ref = useRef();
  const isVisible = useIsVisible(ref);
  return (
    <CardGroup id="turnariProiecte">
      <Card>
        <a href={ProjectImg} ><Card.Img variant="top" alt="proiecte" src={ProjectImg}/></a>
        <Card.Body>
          <Card.Title><strong><h3>Proiecte</h3></strong></Card.Title>
          <Card.Text>
          
            <span className='textTurnari' ref={ref}>{isVisible ? <CountUp start={0} end={7000}  duration={4.75} delay={0.5} suffix="+"/> : "7000+"}</span>
          </Card.Text>
        </Card.Body>
      </Card>
      <Card>
      <a href={VechimeImg}> <Card.Img variant="top" alt="proiecte" src={VechimeImg} /> </a>
        <Card.Body>
          <Card.Title><strong><h3>Vechime</h3></strong></Card.Title>
          <Card.Text>
            <span  className='textTurnari' ref={ref}>{isVisible ? <CountUp start={0} end={15} duration={4.75} delay={0.5} suffix="+"/> : "15+"}</span>
          </Card.Text>
        </Card.Body>
      </Card>
      <Card>
      <a href={UtilajeImg}><Card.Img variant="top" alt="proiecte" src={UtilajeImg}  /></a>
        <Card.Body>
          <Card.Title><strong><h3>Utilaje</h3></strong></Card.Title>
          <Card.Text>
            <span  className='textTurnari' ref={ref}>{isVisible ? <CountUp start={0} end={20} duration={4.75} delay={0.5} suffix="+"/> : "20+"}</span>
          </Card.Text>
        </Card.Body>
      </Card>
    </CardGroup>
  );
}

export default TurnariProiecte;