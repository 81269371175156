import {useEffect, useState} from 'react'
import './Clienti.css';
import FetchVideos from '../../Fetch/FetchVideos';
import ImageGalleryBeton from  '../02. Informatii/ImageGalleryBeton';

const PREFIX_URLThumbnails = "https://infinitystudiogames.com/beton/ImaginiNoi/thumbnails/Terasa/";
const PREFIX_URLMOBILE = "https://infinitystudiogames.com/beton/ImaginiNoi/mobile/terasa/";

const Clienti = () => {
  const [videos, setVideos] = useState({});

  useEffect(() => {
    FetchVideos({ Sheet: 'Clienti' })
    .then(videosData => {
      setVideos(videosData);
    })
    .catch(error => {
      // Handle the error
    });

  }, []);


  return (
    <div>
    <section id="blockClients">
      <div className="container">
        <h2  className="header" > <span>Clientii</span> Nostri</h2>
        <span>Scopul nostru este de a obtine beton elicopterizat la un standard cat mai ridicat si predat la timp clientului.</span>
        <br/>
        <span>Nu pretindem ca suntem cei mai buni, insa va putem garanta faptul ca ne dam toata silinta pentru a reusi sa ne ridicam la asteptarile clientilor nostri, utilizand materiale de calitate superioara.
Dispunem de personal specializat cu o vasta experienta in industria de pavaje din beton elicopterizat, atat in Romania cat si pe teritoriului Europei.</span>
      </div>
	  </section>
    {videos.length > 0 && (
        <ImageGalleryBeton 
          verification={1}
          videos={videos}
          imgVideos={videos}
          PREFIX_URLMOBILE={PREFIX_URLMOBILE}
          thumbnailURL={PREFIX_URLThumbnails}
        />
      )}

    </div>

  );
};

export default Clienti;
