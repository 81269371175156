import React, { useState, useEffect } from 'react'
import './EchipaNoastra.css'
import ImageGalleryBeton from  '../02. Informatii/ImageGalleryBeton';
import fetchImagesCount from '../../Fetch/FetchImages';
import FetchVideos from '../../Fetch/FetchVideos';

const PREFIX_URL_EchipaNoastra = "https://infinitystudiogames.com/beton/ImaginiNoi/Toata%20Echipa/"
const PREFIX_URLThumbnails = "https://infinitystudiogames.com/beton/ImaginiNoi/thumbnails/Toata%20Echipa/";
const PREFIX_URLMOBILE = "https://infinitystudiogames.com/beton/ImaginiNoi/mobile/Toata%20Echipa/";

const EchipaNoastra = () => {
  const [numberOfImagesEchipanNoastra, setNumberImagesEchipaNoastra] = useState(0);
  const [videos, setVideos] = useState({});

  useEffect(() => {
    FetchVideos({ Sheet: 'EchipaBeton' })
    .then(videosData => {
      setVideos(videosData);
    })
    .catch(error => {
      // Handle the error
    });

      fetchImagesCount({ folderImages: 'Toata Echipa' })
      .then(count => {
        //console.log('Number of images:', count);
        setNumberImagesEchipaNoastra(parseInt(count));
      })
      .catch(error => {
        // Handle the error
      });
  }, []);

    return (
      <div>  
        <section id="DespreNoiBlock">
      <div className="container">
      <h2 className="header" style={{textAlign:"center"}}><span>Echipa</span> Beton</h2>

      <span>
      Ne bucuram de o echipa formata din personal calificat, a carui experienta a condus catre realizarea unui numar mare de lucrari pe care le avem in portofoliu, lucrari ale caror performante dureaza pana astazi. ⏳
Realizam lucrari in timpi de executie eficienti, fara ca acest lucru sa interfereze intr-un mod ineficient cu cerintele sau solicitarile dumneavoastra.
Scopul nostru este acela de a eficientiza procesele de productie, iar pentru acest lucru gandirea analitica in perspectiva si sistemul propriu de management ne ajuta foarte mult in demersul nostru.</span>
      </div>
	  </section>
    {videos.length > 0 && (
        <ImageGalleryBeton 
          verification={1}
          videos={videos}
          imgVideos={videos}
          prefixURL={PREFIX_URL_EchipaNoastra}
          numberOfImages={numberOfImagesEchipanNoastra}
          thumbnailURL={PREFIX_URLThumbnails}
          PREFIX_URLMOBILE={PREFIX_URLMOBILE}
        />
      )}
    </div>

  );
};

export default EchipaNoastra;
