import React, { useEffect } from 'react';

const PoliticaCookies = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);
  

 
    return (
      <>
      <div className="container">
      
      <br></br>
     <h3 style={{textAlign:"center"}}>POLITICA COOKIES</h3>
     <span>
     <h3>Acest site utilizeaza cookies</h3>

COOKIES necesare sunt fisiere text, care sunt folosite de site-uri pentru eficientizarea utilizarii si imbunatatirea serviciilor oferite. Aceste fisiere le gasiti pe browser-ul dumneavoastra, pe hard disk-ul dispozitivului in functie de site-urile pe care le vizitati si fac interactiunea cu acestea mai sigura si mai rapida, deoarece isi pot aminti preferintele dumneavoastra.<br></br>
Site-ul nostru foloseste cookies pentru a va distinge de ceilalti utilizatori si acest lucru ne ajuta sa va oferim o experienta mai buna atunci cand navigati pe site. Datele stocate prin fisierele cookies pe site-ul nostru, nu arata detalii personale cu ajutorul carora sa se poata stabili o identitate individuala.<br></br>
Folosim cookies pentru analizarea traficului pe website si pentru personalizarea continutului.<br></br>
<br></br>
Site-ul nu poate functiona la parametri normali fara utilizarea cookies, fara acestea accesul la zonele securizate ale site-ului sau navigarea in pagina ar fi ingreunata.<br></br>
Prin folosirea cookies de statistici, putem analiza informatii despre modul in care folositi site-ul nostru si vom sti ce imbunatatiri putem face.<br></br>
Conform legii, putem stoca cookies pe dispozitivul dvs, doar in cazul in care ele sunt necesare pentru navigarea pe site. Perioada de expirare a acestor fisiere de tip cookie este de maximum 2 ani. Daca nu esti de acord cu folosirea cookies necesare functionarii normale a site-ului, va trebui sa abandonezi navigarea pe site-ul nostru. Pentru diferite alte tipuri de cookies, este necesar acordul dumneavoastra.<br></br>
Va puteti retrage consimtamantul privind fisierele de tip cookie in orice moment. In acest scop, puteti sa blocati sau eliminati fisierele cookie, fie prin intermediul setarilor browser-ului dvs., web sau prin utilizarea unor software-uri puse la dispozitie de terti, sau urmand instructiunile specifice pentru categoriile de cookies din sectiunile urmatoare. In orice situatie, pot aparea probleme legate de utilizarea anumitor parti ale site-ului web, daca dezactivati fisierele de tip cookie.<br></br>
Acordul dumneavoastra pentru folosirea cookies, este valabil pentru domeniul: https://betontehnic.ro/
<br></br>

COOKIES pentru Marketing, sunt folosite pentru a stoca vizitele si cautarile unui utilizator pe web si a-i afisa anunturi relevante si antrenante in functie de cautarile recente. Acest tip de cookies sunt utilizate de agentiile de publicitate.<br></br>
COOKIES Preferinte, permit site-ului sa aminteasca informatii precum limba dvs. preferata sau zona in care va aflati.<br></br>
COOKIES Statistice, ne ajuta sa intelegem cum interactioneaza utilizatorii cu site-ul nostru, prin colectarea informatiilor in mod anonim.<br></br>
Website-ul nostru poate include functii de comunicare sociala ,cum ar fi link-uri catre Facebook,Twitter, Instagram, Linkedin sau Youtube. Aceste functii pot stoca informatii si pot activa un fisier tip cookie pentru o functionare corecta.<br></br>
Website-ul nostru poate utiliza link-uri catre alte site-uri pe care nu le controlam, prin urmare va solicitam sa cititi cu atentie politicile lor in privinta datelor personale si a politicii de confidentialitate.
<br></br>

<h3>Google Analytics</h3>
Website-ul nostru utilizeaza Google Analytics, un serviciu pentru analiza web, furnizat de Google. Google Analytics utilizeaza fisiere de tip cookie pentru a ajuta un anumit Website sa analizeze modul in care utilizati respectivul Website. Informatiile generate de fisierele de tip cookie cu privire la utilizarea de catre dvs. a website-ului (inclusiv adresa dvs. IP), vor fi transmise si stocate de Google pe servere care pot fi localizate in UE, SEE si/sau Statele Unite. Google va utiliza aceste informatii in scopul evaluarii utilizarii de catre dvs. a website-ului, elaborand rapoarte cu privire la activitatea website-ului pentru operatorii website-ului si furnizand alte servicii referitoare la activitatea website-ului si la utilizarea internetului. <br></br>
Daca aveti intrebari sau nelamuriri cu privire la prelucrarea si stocarea datelor dvs., sau daca doriti modificarea sau stergerea acestora, va rugam sa ne contactati la adresa: contact@betonactiv.ro <br></br>
Putem actualiza Politica Cookie si va vom informa despre modificari prin postarea noii variante pe site-ul nostru la sectiunea “Politica Cookie” <br></br>
<br></br>

Va rugam sa consultati periodic aceasta sectiune, pentru a fi la curent cu orice modificare.
<br></br>

<h3>PROTECTIA DATELOR CU CARACTER PERSONAL</h3>

Pentru a accesa, corecta, sterge si limita prelucrarea datelor personale (GDPR), va rugam sa ne contactati la adresa de email: contact@betonactiv.ro<br></br>
Puteti solicita ca datele dvs. cu caracter personal sa poata sau nu fi utilizate sau transmise catre un alt procesor (GDPR) la adresa de email: contact@betonactiv.ro<br></br>
In cazul in care considerati ca datele dvs. personale nu au fost protejate conform legii, aveti dreptul de a depune o plangere la autoritatea de supraveghere (GDPR)<br></br>
<br></br>

     </span>
     </div>
     </>
  );
};

export default PoliticaCookies;
