import React from 'react';
import ImageGallery from 'react-image-gallery';





class ImageGalleryBeton extends React.Component {

constructor(props) {
    super(props);
    
    // Use props.prefixURL instead of PREFIX_URL
    const prefixURL = props.prefixURL || '';
    this.PREFIX_URL = prefixURL; // Assign prefixURL to an instance variable
    const THUMBNAIL_URL  = props.thumbnailURL || '';
    this.PREFIX_URLThumbnails = THUMBNAIL_URL; // Assign prefixURL to an instance variable
    this.PREFIX_URLMOBILE = props.PREFIX_URLMOBILE || ''
    this.videos = props.videos || '';
    this.video = props.video || '';
    this.imgVideos = props.imgVideos || '';
    this.numberOfImages = props.numberOfImages;
    this.verification = props.verification;

    this.state = {
      showIndex: false,
      showBullets: true,
      infinite: true,
      loading: 'lazy',
      showThumbnails: true,
      showFullscreenButton: true,
      showGalleryFullscreenButton: true,
      showPlayButton: true,
      showGalleryPlayButton: true,
      showNav: true,
      isRTL: false,
      slideDuration: 450,
      slideInterval: 2000,
      slideOnThumbnailOver: false,
      thumbnailPosition: 'bottom',
      showVideo: {},
      useWindowKeyDown: true,
    };
    if(this.verification === 1){
    //console.log(this.verification);

      this.images = [
      ].concat(this._getStaticVideos(), this._getStaticImages());
    } else if (this.verification === 2) {
      this.images = [
      
      ].concat(this._getStaticImages());

    }
    else{
    this.images = [
      {
        thumbnail: `${this.PREFIX_URLThumbnails}3.jpg`,
        original: `${this.PREFIX_URL}3.jpg`,
        embedUrl: `${this.video}`,
        description: '',
        loading: this.loading,
        thumbnailLoading: 'lazy',
        originalTitle:  'Lucrari Pardoseli',
        thumbnailTitle: "Thumbnails Pardoseli Industriale Lucrari",
        originalAlt: 'Parodoseli industriale elicopterizate lucrari',
        thumbnailAlt: 'Thumbnail video of Parodoseli industriale elicopterizate lucrari',
        srcSet: `${this.PREFIX_URLMOBILE}3.jpg 380w, ${this.PREFIX_URL}3.jpg 2500w`,
        renderItem: this._renderVideo.bind(this)
      },
      {
        original: `${this.PREFIX_URL}1.jpg`,
        thumbnail: `${this.PREFIX_URLThumbnails}1.jpg`,
        originalClass: 'featured-slide',
        thumbnailClass: 'featured-thumb',
        description: '',
        originalTitle:  'Lucrari Pardoseli',
        thumbnailTitle: "Thumbnails Pardoseli Industriale Lucrari",
        originalAlt: 'Parodoseli industriale elicopterizate lucrari',
        thumbnailAlt: 'Thumbnail image of Parodoseli industriale elicopterizate lucrari',
        srcSet: `${this.PREFIX_URLMOBILE}1.jpg 380w, ${this.PREFIX_URL}3.jpg 2500w`
      },
    ].concat(this._getStaticImages());
  }
  }

  _onImageClick(event) {
    console.debug('clicked on image', event.target, 'at index', this._imageGallery.getCurrentIndex());
  }

  _onImageLoad(event) {
    console.debug('loaded image', event.target.src);
  }

  _onSlide(index) {
    this._resetVideo();
    console.debug('slid to index', index);
  }

  _onPause(index) {
    console.debug('paused on index', index);
  }

  _onScreenChange(fullScreenElement) {
    console.debug('isFullScreen?', !!fullScreenElement);
  }

  _onPlay(index) {
    console.debug('playing from index', index);
  }

  _handleInputChange(state, event) {
    if (event.target.value > 0) {
      this.setState({[state]: event.target.value});
    }
  }

  _handleCheckboxChange(state, event) {
    this.setState({[state]: event.target.checked});
  }

  _handleThumbnailPositionChange(event) {
    this.setState({thumbnailPosition: event.target.value});
  }

  _getStaticImages() {
    let images = [];
    const number = parseInt(this.numberOfImages); // Parse the number as an integer
    //console.log(number + "ceva nu merge");
    if (isNaN(number)) {
      return images; // Return empty array if number is NaN or undefined
    }

    for (let i = 1; i <= number; i++) {
      images.push({
        original: `${this.PREFIX_URL}${i}.jpg`,
        thumbnail: `${this.PREFIX_URLThumbnails}${i}.jpg`,
        loading: this.loading,
        thumbnailLoading: 'lazy',
        originalTitle:  'Lucrari Pardoseli',
        thumbnailTitle: "Thumbnails Pardoseli Industriale Lucrari",
        originalAlt: 'Parodoseli industriale elicopterizate lucrari',
        thumbnailAlt: 'Thumbnail image of Parodoseli industriale elicopterizate lucrari',
        srcSet: `${this.PREFIX_URLMOBILE}${i}.jpg 3x, ${this.PREFIX_URL}${i}.jpg 1x`,
        size:"(max-width: 600px) 100vw, (max-width: 1200px) 50vw, 25vw"
      });
    }

    return images;
  }

  _getStaticVideos() {
    let images = [];
    let videos = this.videos; // Access the video prop using this.props.video
    let img = this.imgVideos;
    

  
    for (let i = 0; i < videos.length; i++) {
    //console.log("videos" + videos[i].linkA);
    //console.log("image" + img[i].linkB);
      images.push({
        thumbnail: img[i].linkB,
        original: img[i].linkC,
        embedUrl: videos[i].linkA, 
        originalAlt: 'Parodoseli industriale elicopterizate lucrari',
        thumbnailAlt: 'Thumbnail image of Parodoseli industriale elicopterizate lucrari',
        originalTitle:  'Lucrari Pardoseli',
        thumbnailTitle: "Thumbnails Pardoseli Industriale Lucrari",
        srcSet: "`` 450w, `img[i].linkC` 2500w",
        srcSet: `${img[i].linkD}3x, ${img[i].linkC} 1x`,
        size:"(max-width: 600px) 100vw, (max-width: 1200px) 50vw, 25vw",
        renderItem: this._renderVideo.bind(this)
      });
    }
  
    return images;
  }
  

  _resetVideo() {
    this.setState({showVideo: {}});

    if (this.state.showPlayButton) {
      this.setState({showGalleryPlayButton: true});
    }

    if (this.state.showFullscreenButton) {
      this.setState({showGalleryFullscreenButton: true});
    }
  }

  _toggleShowVideo(url) {
    const showVideo = this.state;
    this.setState({
      showVideo: {
        ...showVideo,
        [url]: !showVideo[url]
      }
    });

    if (!showVideo[url]) {
      if (this.state.showPlayButton) {
        this.setState({showGalleryPlayButton: false});
      }

      if (this.state.showFullscreenButton) {
        this.setState({showGalleryFullscreenButton: false});
      }
    }
  }

  _renderVideo(item) {
    return (
      <div>
        {
          this.state.showVideo[item.embedUrl] ?
            <div className='video-wrapper'>
                <a
                  className='close-video'
                  onClick={this._toggleShowVideo.bind(this, item.embedUrl)}
                >
                </a>
                <iframe
                  width='560'
                  height='315'
                  src={item.embedUrl}
                  frameBorder='0'
                  allowFullScreen
                >
                </iframe>
            </div>
          :
            <a onClick={this._toggleShowVideo.bind(this, item.embedUrl)}>
              <div className='play-button'></div>
              <img className='image-gallery-image' src={item.original} />
              {
                item.description &&
                  <span
                    className='image-gallery-description'
                    style={{right: '0', left: 'initial'}}
                  >
                    {item.description}
                  </span>
              }
            </a>
        }
      </div>
    );
  }

  render() {
    return (
      <section className='app'>
        <ImageGallery
          ref={i => this._imageGallery = i}
          items={this.images}
          onClick={this._onImageClick.bind(this)}
          onImageLoad={this._onImageLoad}
          lazyLoad={this.loading}
          onSlide={this._onSlide.bind(this)}
          onPause={this._onPause.bind(this)}
          onScreenChange={this._onScreenChange.bind(this)}
          onPlay={this._onPlay.bind(this)}
          infinite={this.state.infinite}
          showBullets={this.state.showBullets}
          showFullscreenButton={this.state.showFullscreenButton && this.state.showGalleryFullscreenButton}
          showPlayButton={this.state.showPlayButton && this.state.showGalleryPlayButton}
          showThumbnails={this.state.showThumbnails}
          showIndex={this.state.showIndex}
          showNav={this.state.showNav}
          isRTL={this.state.isRTL}
          thumbnailPosition={this.state.thumbnailPosition}
          slideDuration={parseInt(this.state.slideDuration)}
          slideInterval={parseInt(this.state.slideInterval)}
          slideOnThumbnailOver={this.state.slideOnThumbnailOver}
          additionalClass="app-image-gallery"
          useWindowKeyDown={this.state.useWindowKeyDown}
        />

      
      
      </section>
    );
  }
}

export default ImageGalleryBeton;