import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./Layout/Layout";
import Acasa from "./Acasa";
import NoPage from "./Layout/NoPage";
import Footer from "./Layout/Antet/Footer"
import PoliticaDeConfidentialitate from "./Layout/Antet/PoliticaDeConfidentialitate";
import PoliticaCookies from "./Layout/Antet/PoliticaCookies";
import Portofoliu from "./Layout/NavBarElements/Portofoliu";
import Informatii from "./Pagini/02. Informatii/Informatii";
import { FacebookProvider } from './Utilitati/FacebookContext'; // Import the context provider


export default function App() {
  const navigationType = window.performance.navigation.type;if(navigationType === 1){const element = document.getElementById('facebook');if (element) {element.style.top = '-68px';}};
  return (
  <FacebookProvider>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Acasa />} />
          {/*<Route path="/contact" element={<Contact />}></Route>*/}
          <Route path="/portofoliu" element={<Portofoliu />}></Route>
          <Route path="/informatii" element={<Informatii />}></Route>

          <Route path="/GDPR" element={<PoliticaDeConfidentialitate />}></Route>
          <Route path="/cookies" element={<PoliticaCookies />}></Route>

          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
      <Footer />

    </BrowserRouter>
  </FacebookProvider>

  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);