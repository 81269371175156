import { useState, useEffect } from 'react';
import './Header.css'
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link, useLocation } from 'react-router-dom';

export default function Header() {
  const [anchorEl, setAnchorEl] = useState("");
  const open = Boolean(anchorEl);
  const location = useLocation();
  const [isHomePage, setIsHomePage] = useState(false);
  const [clickCompanie, setCompanie] = useState(false);
  const [eventButton, setEventButton] = useState(null);


  useEffect(() => {
    setIsHomePage(location.pathname === '/');
    if(clickCompanie){
      setTimeout(() => {
        setAnchorEl(eventButton);
        setCompanie(false);
      }, 300);
    }
  }, [location.pathname]);

  const handleClick = (event) => {

    if(isHomePage){
      setAnchorEl(event.currentTarget);

    }
    setEventButton(event.currentTarget);

  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickToCompani = () => {
    setCompanie(true);

    
  }

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className="fromLeft"
      >
        {!isHomePage ? (<Link to='/' onClick={handleClickToCompani}>Companie</Link>) : <span style={{fontSize:"16px"}}>Companie</span>}

      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
          disableScrollLock={true}

      >
        <MenuItem onClick={handleClose}><a href="#DespreNoi">Despre Noi</a></MenuItem>
        <MenuItem onClick={handleClose}><a href="#EchipaNoastra">Echipa Noastra</a></MenuItem>
        <MenuItem onClick={handleClose}><a href="#Clienti">Clienti Nostri</a></MenuItem>
        <MenuItem onClick={handleClose}><a href="#Servicii">Servicii Oferite</a></MenuItem>
        <MenuItem onClick={handleClose}><a href="#footer">Contact</a></MenuItem>
      </Menu>
    </>
  );
}