import './Acasa.css';
import Video from './Pagini/01. Acasa/Video';
import Servicii from './Pagini/01. Acasa/Servicii'
import DespreNoi from './Pagini/01. Acasa/DespreNoi';
import TurnariProiecte from './Pagini/01. Acasa/TurnariProiecte';
import Clienti from './Pagini/01. Acasa/Clienti';
import FacebookIcon  from './Imagini/facebook30x30.png';
import InstagramIcon  from './Imagini/instagram30x30.png';

import GoogleIcon  from './Imagini/google.png';
import YoutubeIcon  from './Imagini/youtube30x30.png';


import EchipaNoastra from './Pagini/01. Acasa/EchipaNoastra';

function Acasa() {

  return (
    <div className="App">

		 <section id="showcase" >
			<Video />

				
     
		  </section>


    <section id="newsletter">
      <div className="container">
	  <h2><span style={{color:'#ED1C24'}}>Pardoseli</span> Industriale Elicopterizate </h2>

        
			<div id="icon">
            
			<a target="_blank" title="follow me on facebook" href="https://www.facebook.com/Pardoseli-Elicopterizate-Bucure&#x219;ti-1606430106090797/"><img alt="follow me on facebook" src={FacebookIcon} border={0}/></a>
			<a id="youtubeicon" target="_blank" title="follow me on youtube" href="https://www.youtube.com/@betontehnicactiv9691"><img alt="follow me on youtube" src={YoutubeIcon} border={0}/></a>
			<a target="_blank" title="follow me on instagram" href="https://www.instagram.com/beton_elicopterizat_/?hl=ro"><img alt="follow me on instagram" src={InstagramIcon} border={0}/></a>
			<a target="_blank" title="See my location on google map" href="https://pardoseli-industriale.business.site/?m=true"><img alt="follow me on google site" src={GoogleIcon} border={0} height="34"/></a>

			</div>
		
		
      </div>

	  
    </section>
	<br/>
	<br/>
	
	<div id="DespreNoi">
		<TurnariProiecte />
		<DespreNoi />
		
	</div>
	<br/>
	<br/>
	<div id="EchipaNoastra">
		<EchipaNoastra />
	</div>
	<div id="Clienti">
		<Clienti />
	</div>
	<br/>
	<br/>
	<div id="Servicii" >
	<Servicii />
	</div>



	
		


	
	
    </div>
  );
}

export default Acasa;
