import React, { Component, useState, useEffect } from 'react';
import videoSmall from '../../Videoclipuri/betonHomePage.mp4';
import videoLarge from '../../Videoclipuri/betonHomePageBiggerSize.mp4';

const Video = () => {
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 425);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 425);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const videoSource = isLargeScreen ? videoLarge : videoSmall;

  return (
    <video src={videoSource} width="100%" height="100%" autoPlay muted loop>
    </video>
  );
};

export default Video;
