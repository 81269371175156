import './DespreNoi.css'
import React from 'react'

const FolosireaBetonului = () => {
  
    return (
      <div>  
        <section id="DespreNoiBlock">
      <div className="container">
      <h2 className="header" style={{textAlign:"center"}}>Informatii despre <span>Pardoseli industriale</span> de ce sa le folositi?</h2>
      
                <div>
                <span>
      Betonul elicopterizat are in compozitie beton armat cu fibra de otel, plasa sudata sau fibre de polipropilena. 
Dupa turnare, betonul intins si de cele mai multe ori vibrat, trebuie lasat sa se usuce pana la un anumit punct. 
Ulterior, suprafata se elicopterizeaza cu ajutorul sculelor si dispozitivelor, se aplica un strat de quartz pentru rezistenta si se finiseaza. 
La cererea clientului se poate aplica si un sigilant de pardoseala cu rol de durificare si antipraf. 
Culoarea betonului si a quartz-ului poate varia in functie de solicitarile dumneavoastra. 
Finisajele pot varia si ele avand un aspect deosebit de neted sau antiderapant (maturat).
Pardoselile industriale elicopterizate sunt recomandate atat la exterior, cat si pentru interior, pentru suprafete cu trafic intens precum: trotuare, parcari, garaje, depozite, hale, etc.
Un alt avantaj major al betonului elicopterizat  il reprezinta pretul scazut , mult mai  convenabil decat cel al altor tipuri de pavaje. 
Si din punct de vedere al timpului necesar executarii unei astfel de suprafete betonul elicopterizat  reprezinta o solutie rapida.
In ceea ce priveste intretinerea, betonul elicopterizat poate fi foarte usor de curatat.</span>
                </div>
                <div>
                <h2>Elicopterizarea pardoselilor, ce inseamna?</h2>
<span>Sapa, pardoseala sau alte aplicatii din beton pot fi finisate cu un aparat - elicopterizat. Astfel, se obtine o suprafata protectoare, neteda, cu un aspect placut si cu rezistenta sporita la trafic. 
Mai exact, inainte ca betonul sa se intareasca, se folosesc diferite tipuri de „elicoptere”, adica niste utilaje pentru slefuit si nivelat.</span>
                </div>
                <div>
                <h2>Avantajele Practice ale Pardoselilor din beton elicopterizat</h2>
<span>Adesea, pardoselile din beton elicopterizat sunt considerate ca fiind foarte plăcute din punct de vedere estetic. Dar există si motive practice pentru a alege un beton elicopterizat, cum ar fi  duritatea acestuia si intretinerea foarte usoara.
Să aflăm ce alte avantaje ale unei pardoseli de beton elicopterizat sunt:</span>
                </div>
                <div>
                <span>
1. Este o alternativă foarte buna la pardoselile clasice acoperite cu rasini sau vopsele. Pardoselile elicopterizate se realizeaza prin șlefuirea mecania a unei pardoseli din beton proaspăt, tratata in prealabil cu un cuart intaritor la suprafata. Prin aceasta actiune se va netezi suprafata pana la obtinerea luciului dorit!
<br />
2. Datorită suprafeței sale netede, betonul elicopterizat este mai puțin predispus la aparitia fisurilor. Este de asemenea impermeabil și rezistent la șocuri.
<br />
3. Este ideal pentru trafic intens. Motostivuitoarele și alte vehicule grele nu vor afecta finisajul pardoselii, iar petele pot fi ușor îndepărtate.
<br />
4. Puncturarea este rezultatul unei greutăți concentrate intr-un singur punct. Pe o suprafață de beton elicopterizat, aceste imperfecțiuni sunt mai puțin vizibile lucru care înseamnă că este nevoie de mai puțină întreținere pe termen lung.
<br />
5. Cu o compoziție de beton potrivita, pardoselile elicopterizate au o excelenta rezistență chimică și termică.
<br />
6. În ceea ce privește întreținerea, repararea și curățarea, niciun fel de pardoseala din beton nu reprezinta o mai buna alternativa. Aspectul neted și strălucitor va dura mulți ani (cu atât mai mult daca suprafata va fi sigilata cu un curing )!</span>
                </div>
            
     



      </div>
        <br></br>
	  </section>
  
    </div>
  );
};

export default FolosireaBetonului;
