function FetchVideos({ Sheet }) {

    return fetch(`https://infinitystudiogames.com/beton/backend/videos.php?sheet=${Sheet}`)
      .then(response => response.json())
      .then(data => {
      //  console.log(data);
        return data;
      })
      .catch(error => {
        console.log('Error:', error);
        // Handle the error within the component or in a higher-level component
      });

}

export default FetchVideos;
