import React, { useState, useEffect } from 'react';
import CookieSettingsModal from './CookieSettingsModal'; // Import the modal component
import { useFacebookStyle } from '../../Utilitati/FacebookContext'; // Import the context hook

const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { setFacebookTop } = useFacebookStyle(); // Access the setFacebookTop function from the context

  // Check if the user has previously accepted cookies
  useEffect(() => {
    const hasUserAcceptedCookies = localStorage.getItem('cookie-consent-accepted');
    if (hasUserAcceptedCookies) {
      setShowBanner(false); // Hide the banner if cookies were already accepted
    }
  }, []);

  const handleAcceptCookies = () => {
    // Place the code here to remove Facebook cookies
    document.cookie = "checkpoint=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=.facebook.com/Cookies/; domain=.facebook.com;";
    // ... (remove other Facebook cookies as needed)

    localStorage.setItem('cookie-consent-accepted', 'true');
    setShowBanner(false);
    // Change the top value for #facebook to -68px
    setFacebookTop('0px');
    // Load non-essential cookies and scripts here
  };

  const handleManageCookies = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
     // Change the top value for #facebook to -68px
     setFacebookTop('-0px');
  };

  const handleSaveSettings = () => {
    // Close the banner when "Save Settings" is clicked
    setShowBanner(false);
    // Change the top value for #facebook to -68px
    setFacebookTop('-0px');
    
  };

  useEffect(() => {
    if (isModalOpen) {
      // Disable scrolling when the modal is open
      document.body.style.overflow = 'hidden';
    } else {
      // Enable scrolling when the modal is closed
      document.body.style.overflow = 'auto';
    }

    // Cleanup the effect
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isModalOpen]);

  return (
    <div>
      {showBanner && (
        <div className="cookie-banner">
          <p>Acest site web folosește cookie-uri pentru a vă îmbunătăți experiența. Vă rugăm să vă gestionați preferințele privind cookie-urile.</p>
          <button className="cookie-button" onClick={handleManageCookies}>
            Setari Cookies
          </button>
          <button className="cookie-button" onClick={handleAcceptCookies}>
            Acceptați toate cookie-urile
          </button>
        </div>
      )}
      {isModalOpen && (
        <>
          <div className="modal-overlay"></div> {/* Modal overlay */}
          <CookieSettingsModal onClose={closeModal} onSaveSettings={handleSaveSettings} />
        </>
      )}
    </div>
  );
};

export default CookieBanner;
